var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Injectable, BaseService } from './service.base';
import { action, makeObservable, observable } from 'mobx';
export let AddCreditCardService = class AddCreditCardService extends BaseService {
    cardNumberValid = false;
    cardNumberError = false;
    expiryDateError = false;
    cvcError = false;
    btnDisabled = true;
    cardSaveOptionChecked = true;
    touchedFields = [];
    constructor() {
        super();
        makeObservable(this);
    }
    onCardNumberBlur = (cardType, erroredInputs) => {
        if (cardType && !this.cardNumberError) {
            this.cardNumberValid = true;
        }
        else {
            this.cardNumberValid = false;
        }
        this.setTouchedFields('card_number');
        this.validateAllFields();
    };
    onCardNumberError = (cardNumberError) => {
        if (cardNumberError) {
            this.cardNumberError = true;
            this.cardNumberValid = false;
        }
    };
    onCardNumberFocus = () => {
        this.cardNumberValid = false;
        this.cardNumberError = false;
    };
    onExpiryDateBlur = () => {
        this.setTouchedFields('expiry_date');
        this.validateAllFields();
    };
    onExpiryDateError = (expiryDateError) => {
        if (expiryDateError) {
            this.expiryDateError = true;
        }
    };
    onExpiryDateFocus = () => {
        this.expiryDateError = false;
    };
    onCvcBlur = () => {
        this.setTouchedFields('cvc');
        this.validateAllFields();
    };
    onCvcError = (cvcError) => {
        if (cvcError) {
            this.cvcError = true;
        }
    };
    onCvcFocus = () => {
        this.cvcError = false;
    };
    validateAllFields = () => {
        this.btnDisabled = this.touchedFields.length < 3 || this.cardNumberError || this.expiryDateError || this.cvcError;
    };
    setTouchedFields = (fieldName) => {
        if (!this.touchedFields.includes(fieldName)) {
            this.touchedFields.push(fieldName);
        }
    };
    handleCardSaveOptionChange = () => {
        this.cardSaveOptionChecked = !this.cardSaveOptionChecked;
    };
    handleBtnClick = () => {
    };
};
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AddCreditCardService.prototype, "cardNumberValid", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AddCreditCardService.prototype, "cardNumberError", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AddCreditCardService.prototype, "expiryDateError", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AddCreditCardService.prototype, "cvcError", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AddCreditCardService.prototype, "btnDisabled", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AddCreditCardService.prototype, "cardSaveOptionChecked", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "onCardNumberBlur", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "onCardNumberError", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "onCardNumberFocus", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "onExpiryDateBlur", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "onExpiryDateError", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "onExpiryDateFocus", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "onCvcBlur", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "onCvcError", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "onCvcFocus", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "validateAllFields", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "setTouchedFields", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "handleCardSaveOptionChange", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "handleBtnClick", void 0);
AddCreditCardService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [])
], AddCreditCardService);
