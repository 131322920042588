var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Injectable, BaseService } from './service.base';
import { action, makeObservable, observable } from 'mobx';
import { PaymentType } from '~/services/service.enums';
export let PaymentService = class PaymentService extends BaseService {
    paymentMethod = PaymentType.creditCard;
    showSuccessBlock = false;
    showAllMode = true;
    slideArrowLeftEnabled = false;
    slideArrowRightEnabled = true;
    constructor() {
        super();
        makeObservable(this);
    }
    handleViewAll = () => {
        this.showAllMode = !this.showAllMode;
    };
    handleSwitchPayment = (e) => {
        e.preventDefault();
        this.paymentMethod = this.paymentMethod === PaymentType.creditCard ? PaymentType.paypal : PaymentType.creditCard;
    };
    handleSaveCardChange = () => {
    };
    confirmAndPay = () => {
        this.showSuccessBlock = true;
    };
    handleSlideChange = (swiper) => {
        if (swiper.isBeginning) {
            this.slideArrowLeftEnabled = false;
        }
        else {
            this.slideArrowLeftEnabled = true;
        }
        if (swiper.isEnd) {
            this.slideArrowRightEnabled = false;
        }
        else {
            this.slideArrowRightEnabled = true;
        }
    };
};
__decorate([
    observable,
    __metadata("design:type", String)
], PaymentService.prototype, "paymentMethod", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaymentService.prototype, "showSuccessBlock", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaymentService.prototype, "showAllMode", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaymentService.prototype, "slideArrowLeftEnabled", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaymentService.prototype, "slideArrowRightEnabled", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaymentService.prototype, "handleViewAll", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaymentService.prototype, "handleSwitchPayment", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaymentService.prototype, "handleSaveCardChange", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaymentService.prototype, "confirmAndPay", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaymentService.prototype, "handleSlideChange", void 0);
PaymentService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [])
], PaymentService);
