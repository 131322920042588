var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from 'mobx';
import { Injectable } from './service.base';
export let LinkAccountDialogService = class LinkAccountDialogService {
    open = false;
    showSignUp = false;
    showStep1 = true;
    showLinkAccount = false;
    selectedCountry = '0';
    phoneNumber;
    isPhoneInvalid = false;
    isEmailInvalid = false;
    isDateOfBirthInvalid = false;
    isCreateAccountBtnDisabled = true;
    account = {};
    constructor() {
        makeObservable(this);
    }
    handleOpen = () => (this.open = true);
    handleClose = () => {
        this.open = false;
        this.showStep1 = true;
        this.showSignUp = false;
        this.showLinkAccount = false;
    };
    handleOpenSignUp = () => {
        this.showStep1 = false;
        this.showSignUp = true;
    };
    handleOpenLinkAccount = () => {
        this.showStep1 = false;
        this.showLinkAccount = true;
    };
    handleCountryChange = (event) => {
        this.selectedCountry = event.target.value;
        if (this.selectedCountry === '0') {
            this.account.country = null;
        }
        else {
            this.account.country = this.selectedCountry;
        }
        this.validateAllFields();
    };
    handlePhoneNumberBlur = (isValid, fullNumber) => {
        if (fullNumber) {
            this.isPhoneInvalid = !isValid;
            this.account.phone = fullNumber;
        }
        else {
            this.account.phone = null;
        }
        this.validateAllFields();
    };
    handlePhoneNumberFocus = () => {
        this.isPhoneInvalid = false;
    };
    handleEmailBlur = (event) => {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value)) {
            this.isEmailInvalid = true;
            this.account.email = null;
        }
        else {
            this.isEmailInvalid = false;
            this.account.email = event.target.value;
        }
        this.validateAllFields();
    };
    handleEmailFocus = () => {
        this.isEmailInvalid = false;
    };
    handleFirstNameBlur = (event) => {
        if (event.target.value) {
            this.account.firstName = event.target.value;
        }
        else {
            this.account.firstName = null;
        }
        this.validateAllFields();
    };
    handleLastNameBlur = (event) => {
        if (event.target.value) {
            this.account.lastName = event.target.value;
        }
        else {
            this.account.lastName = null;
        }
        this.validateAllFields();
    };
    handleDateOfBirthBlur = (event) => {
        if (event.target.value) {
            const dateArr = event.target.value.split('/');
            if (dateArr.length < 3) {
                this.isDateOfBirthInvalid = true;
            }
            else {
                const month = dateArr[0];
                const day = dateArr[1];
                const year = dateArr[2];
                let isValid = true;
                if (month > 12) {
                    isValid = false;
                }
                if (isValid && day > 31) {
                    isValid = false;
                }
                const currentYear = new Date().getFullYear();
                const allowYear = currentYear - 18;
                if (isValid && (year > allowYear || year < 1000)) {
                    isValid = false;
                }
                this.isDateOfBirthInvalid = !isValid;
                this.account.dateBirth = isValid ? event.target.value : null;
            }
        }
        else {
            this.account.dateBirth = null;
        }
        this.validateAllFields();
    };
    handleDateOfBirthFocus = () => {
        this.isDateOfBirthInvalid = false;
    };
    validateAllFields = () => {
        if (this.account.firstName && this.account.lastName
            && this.account.dateBirth && this.account.country
            && this.account.email && !this.isPhoneInvalid) {
            this.isCreateAccountBtnDisabled = false;
        }
        else {
            this.isCreateAccountBtnDisabled = true;
        }
    };
};
__decorate([
    observable,
    __metadata("design:type", Boolean)
], LinkAccountDialogService.prototype, "open", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], LinkAccountDialogService.prototype, "showSignUp", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], LinkAccountDialogService.prototype, "showStep1", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], LinkAccountDialogService.prototype, "showLinkAccount", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], LinkAccountDialogService.prototype, "selectedCountry", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], LinkAccountDialogService.prototype, "phoneNumber", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], LinkAccountDialogService.prototype, "isPhoneInvalid", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], LinkAccountDialogService.prototype, "isEmailInvalid", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], LinkAccountDialogService.prototype, "isDateOfBirthInvalid", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], LinkAccountDialogService.prototype, "isCreateAccountBtnDisabled", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleOpen", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleClose", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleOpenSignUp", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleOpenLinkAccount", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleCountryChange", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handlePhoneNumberBlur", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handlePhoneNumberFocus", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleEmailBlur", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleEmailFocus", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleFirstNameBlur", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleLastNameBlur", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleDateOfBirthBlur", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "handleDateOfBirthFocus", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], LinkAccountDialogService.prototype, "validateAllFields", void 0);
LinkAccountDialogService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [])
], LinkAccountDialogService);
