var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { action, makeObservable, observable } from 'mobx';
import { Injectable } from './service.base';
export let RedeemDialogService = class RedeemDialogService {
    open = false;
    showStep2 = false;
    showStep1 = true;
    slideArrowLeftEnabled = false;
    slideArrowRightEnabled = true;
    selectedLocation = '0';
    redeemAmounts;
    selectedRedeemAmount = false;
    constructor() {
        makeObservable(this);
        this.redeemAmountsInit();
    }
    handleOpen = () => {
        this.open = true;
    };
    handleClose = () => {
        this.open = false;
        this.showStep1 = true;
        this.showStep2 = false;
    };
    handleOpenStep2 = () => {
        this.showStep1 = false;
        this.showStep2 = true;
    };
    handleOpenStep1 = () => {
        this.showStep1 = true;
        this.showStep2 = false;
    };
    handleLocationChange = (event) => {
        this.selectedLocation = event.target.value;
    };
    redeemAmountsInit = () => {
        const amounts = [50, 100, 150, 200, 250];
        const userTotalBalance = 200;
        const redeemAmounts = [];
        for (const item of amounts) {
            redeemAmounts.push({
                value: item,
                disabled: item > userTotalBalance,
                active: false
            });
        }
        this.redeemAmounts = redeemAmounts;
    };
    handleSlideChange = (swiper) => {
        if (swiper.isBeginning) {
            this.slideArrowLeftEnabled = false;
        }
        else {
            this.slideArrowLeftEnabled = true;
        }
        if (swiper.isEnd) {
            this.slideArrowRightEnabled = false;
        }
        else {
            this.slideArrowRightEnabled = true;
        }
    };
    handleSlideClick = (redeemAmount) => {
        if (!redeemAmount.disabled) {
            const currentActive = this.redeemAmounts.find(item => item.active === true);
            if (currentActive) {
                currentActive.active = false;
            }
            this.selectedRedeemAmount = true;
            redeemAmount.active = true;
        }
    };
};
__decorate([
    observable,
    __metadata("design:type", Boolean)
], RedeemDialogService.prototype, "open", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], RedeemDialogService.prototype, "showStep2", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], RedeemDialogService.prototype, "showStep1", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], RedeemDialogService.prototype, "slideArrowLeftEnabled", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], RedeemDialogService.prototype, "slideArrowRightEnabled", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], RedeemDialogService.prototype, "selectedLocation", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Array !== "undefined" && Array) === "function" ? _a : Object)
], RedeemDialogService.prototype, "redeemAmounts", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], RedeemDialogService.prototype, "selectedRedeemAmount", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "handleOpen", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "handleOpenStep2", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "handleOpenStep1", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "handleLocationChange", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "redeemAmountsInit", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "handleSlideChange", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "handleSlideClick", void 0);
RedeemDialogService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [])
], RedeemDialogService);
