export var EApiUrl;
(function (EApiUrl) {
    EApiUrl["auth"] = "auth";
    EApiUrl["communities"] = "communities";
    EApiUrl["thesaurus"] = "thesaurus";
    EApiUrl["searchTemplates"] = "search-templates";
    EApiUrl["children"] = "children";
    EApiUrl["properties"] = "properties";
    EApiUrl["tags"] = "tags";
    EApiUrl["adminUsers"] = "admin-users";
    EApiUrl["files"] = "files";
    EApiUrl["related"] = "related";
    EApiUrl["ownershipHistory"] = "ownership-history";
    EApiUrl["shelves"] = "shelves";
    EApiUrl["books"] = "books";
    EApiUrl["sheets"] = "sheets";
    EApiUrl["content"] = "content";
    EApiUrl["users"] = "users";
    EApiUrl["autoSignIn"] = "auto-sign-in";
    EApiUrl["download"] = "download";
})(EApiUrl || (EApiUrl = {}));
