export var AccountMenuItem;
(function (AccountMenuItem) {
    AccountMenuItem["personalInfo"] = "personal-info";
    AccountMenuItem["payments"] = "payments";
})(AccountMenuItem || (AccountMenuItem = {}));
export var SupportMenuItem;
(function (SupportMenuItem) {
    SupportMenuItem["support"] = "support";
    SupportMenuItem["messages"] = "messages";
})(SupportMenuItem || (SupportMenuItem = {}));
