import { jsx as _jsx } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AppWrapper } from '~/views/organisms/wrapper-app/wrapper.app';
import { ROUTES } from './routes';
import { PageNotFound } from '~/pages/page.notfound';
import { PageHome } from '~/pages/page.home';
import { PagePayment } from '~/pages/page.payment';
import { PageSupport } from '~/pages/page.support';
import { PageAccount } from '~/pages/page.account';
const router = createBrowserRouter([
    {
        path: ROUTES.MAIN.path,
        element: _jsx(AppWrapper, {}),
        children: [
            {
                path: ROUTES.MAIN.path,
                element: _jsx(PageHome, {})
            }
        ]
    },
    {
        path: ROUTES.PAYMENT.path,
        element: _jsx(AppWrapper, {}),
        children: [
            {
                path: ROUTES.PAYMENT.path,
                element: _jsx(PagePayment, {})
            }
        ]
    },
    {
        path: ROUTES.SUPPORT.path,
        element: _jsx(AppWrapper, {}),
        children: [
            {
                path: ROUTES.SUPPORT.path,
                element: _jsx(PageSupport, {})
            }
        ]
    },
    {
        path: ROUTES.PERSONAL_INFO.path,
        element: _jsx(AppWrapper, {}),
        children: [
            {
                path: ROUTES.PERSONAL_INFO.path,
                element: _jsx(PageAccount, {})
            }
        ]
    },
    {
        path: ROUTES.ACCOUNT_PAYMENTS.path,
        element: _jsx(AppWrapper, {}),
        children: [
            {
                path: ROUTES.ACCOUNT_PAYMENTS.path,
                element: _jsx(PageAccount, {})
            }
        ]
    },
    {
        path: ROUTES.MESSAGES.path,
        element: _jsx(AppWrapper, {}),
        children: [
            {
                path: ROUTES.MESSAGES.path,
                element: _jsx(PageSupport, {})
            }
        ]
    },
    {
        path: '*',
        element: _jsx(AppWrapper, {}),
        children: [
            {
                path: '*',
                element: _jsx(PageNotFound, {})
            }
        ]
    },
]);
export const AppRouter = observer(() => {
    return _jsx(RouterProvider, { router: router, fallbackElement: true });
});
