/* eslint-disable @typescript-eslint/no-empty-interface */
import { createTheme } from '@mui/material/styles';
const FONT_FAMILY = `'DMSans'`;
const FONT_FAMILY_POPPINS = `'Poppins'`;
const palette = {
    background: {
        default: '#FFFFFF',
        paper: '#F7F7F7',
    },
    primary: {
        main: '#006FA7',
        light: '#006FA70D',
        dark: '#006FA7',
    },
    secondary: {
        main: '#AE5E06',
        light: '#AE5E060D',
    },
    label: {
        shrink: '#AE5E06',
    },
    info: {
        main: '#49454F',
        dark: '#49454F',
        light: '#49454F8F',
    },
    neutral: {
        main: '#49454F',
    },
    text: {
        primary: '#1C1B1F',
        secondary: '#006FA7',
        disabled: '#ffffffa3',
        white: '#FFFFFF',
    },
    warning: {
        main: '#F7A501',
    },
    grey: {
        '700': '#7B797C',
        '600': '#B7B5B9',
        '500': '#D5D4D8',
        '400': '#EEEEEE',
        '300': '#F2F2F7',
        '200': '#49454F0D',
        '100': '#00000014',
        '50': '#0000004d',
        A100: '#e7e7e7',
        A200: '#00000042',
    },
};
const shadows = Array(25).fill('none');
shadows[10] = 'inset 0px -1px 0px rgba(0, 0, 0, 0.08)'; // app bar
shadows[11] = '0px 5px 12px rgba(0, 0, 0, 0.2)'; //button hover
shadows[12] = '0px 4px 12px 4px rgba(0, 0, 0, 0.04)'; // menu
export const theme = createTheme({
    palette,
    shadows,
    typography: {
        fontFamily: FONT_FAMILY,
        allVariants: {
            color: '#FCFCFD',
        },
        h1: {
            fontSize: 16,
            fontWeight: 700,
            lineHeight: 1.5,
            letterSpacing: 0.5,
            textTransform: 'uppercase',
        },
        h2: {
            fontSize: 16,
            fontWeight: 600,
            lineHeight: 1.38,
        },
        h4: {
            fontSize: 12,
            fontWeight: 500,
            lineHeight: 1.35,
            opacity: 0.5,
            letterSpacing: 0.5,
            color: palette.info.dark,
        },
        subtitle1: {
            fontSize: 16,
            fontWeight: 700,
            lineHeight: 1,
            letterSpacing: 0.5,
        },
        body1: {
            fontSize: 16,
            lineHeight: 1.38,
            letterSpacing: 0.5,
        },
        body2: {
            fontSize: 14,
            lineHeight: 1.43,
            letterSpacing: 0.5,
        },
        caption: {
            fontSize: 12,
            fontWeight: 500,
            lineHeight: 1.33,
            letterSpacing: 0.5,
        },
    },
    components: {
        MuiInputBase: {
            styleOverrides: {
                input: {
                    fontFamily: FONT_FAMILY,
                    '&::placeholder': {
                        color: palette.neutral.main,
                        fontSize: '16px',
                        opacity: 0.64,
                    },
                    color: palette.neutral.main,
                    fontWeight: 400,
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                input: {
                    fontFamily: FONT_FAMILY,
                    '&.MuiAutocomplete-input': {
                        paddingBottom: 0,
                        paddingTop: 0,
                        height: '30px',
                    },
                },
                paper: {
                    fontFamily: FONT_FAMILY,
                    backgroundColor: palette.background.default,
                    boxShadow: shadows[12],
                },
                root: {
                    fontFamily: FONT_FAMILY,
                    '& .MuiInputLabel-root, & .MuiAutocomplete-input': {
                        color: palette.neutral.main,
                        fontWeight: 400,
                        '&::placeholder': {
                            opacity: 0.64,
                        },
                    },
                    '& .MuiInputLabel-root': {
                        '&.MuiInputLabel-shrink': {
                            color: palette.label.shrink,
                            opacity: 1,
                            borderColor: palette.label.shrink,
                        },
                    },
                    '&.Mui-focused .MuiOutlinedInput-root': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: palette.label.shrink,
                        },
                    },
                },
                noOptions: {
                    display: 'none',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                colorDefault: { background: palette.background.paper },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontFamily: FONT_FAMILY,
                    color: palette.text.primary,
                    fontWeight: 600,
                    lineHeight: 1.4,
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: palette.neutral.main,
                    '&.Mui-checked': {
                        color: palette.primary.main,
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontFamily: FONT_FAMILY,
                    letterSpacing: 0.5,
                },
            },
        },
        MuiButton: {
            defaultProps: {
                variant: 'contained',
            },
            styleOverrides: {
                root: {
                    fontFamily: FONT_FAMILY,
                    textTransform: 'initial',
                    letterSpacing: 0.5,
                    borderRadius: 0,
                    ':hover': {
                        boxShadow: shadows[11],
                    },
                    '&.Mui-disabled': {
                        backgroundColor: palette.grey['500'],
                        color: palette.text.white,
                    },
                },
                colorInherit: {
                    backgroundColor: palette.primary.light,
                    color: palette.primary.main,
                    ':hover': {
                        backgroundColor: palette.primary.light,
                    },
                },
                sizeMedium: {
                    fontSize: 16,
                    fontWeight: 700,
                    letterSpacing: 0.5,
                    padding: '10px 16px',
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '& .MuiInputLabel-root': {
                        fontFamily: FONT_FAMILY,
                        color: palette.neutral.main,
                        opacity: 0.64,
                        fontWeight: 400,
                    },
                    '& .MuiInputBase-root': {
                        borderRadius: '2px',
                        borderColor: palette.info.light,
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                colorInherit: {
                    background: palette.primary.light,
                    color: palette.primary.main,
                    borderRadius: 0,
                    ':hover': {
                        background: palette.primary.light,
                    },
                    '& .MuiTouchRipple-root .MuiTouchRipple-child': {
                        borderRadius: 0,
                    },
                },
                root: {
                    marginRight: 0,
                    fontFamily: FONT_FAMILY,
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    fontFamily: FONT_FAMILY,
                },
            },
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    color: palette.text.primary,
                    background: palette.background.paper,
                },
                label: {
                    fontWeight: 500,
                    fontFamily: FONT_FAMILY,
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                flexContainer: {
                    gap: 8,
                },
                root: {
                    minHeight: 'auto',
                    fontFamily: FONT_FAMILY,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontFamily: FONT_FAMILY,
                    minHeight: 32,
                    minWidth: 50,
                    padding: '6px 12px',
                    textTransform: 'initial',
                    backgroundColor: palette.secondary.light,
                    color: palette.secondary.main,
                    '&.Mui-selected': {
                        backgroundColor: palette.secondary.main,
                        color: palette.text.white,
                    },
                    letterSpacing: 0.5,
                },
            },
        },
        MuiOutlinedInput: {
            defaultProps: { color: 'info' },
            styleOverrides: {
                input: { padding: '12.5px 16px' },
                root: {
                    fontFamily: FONT_FAMILY,
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent'
                    },
                },
            },
        },
        MuiFilledInput: {
            defaultProps: { color: 'primary' },
            styleOverrides: {
                input: { padding: '8.5px 12px' },
                root: {
                    fontFamily: FONT_FAMILY,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    backgroundColor: palette.primary.light,
                    ':before': {
                        border: 'unset !important',
                    },
                    ':after': {
                        border: 'unset !important',
                    },
                    '&:hover': {
                        backgroundColor: palette.primary.light,
                    },
                    '&.Mui-focused': {
                        backgroundColor: palette.primary.light,
                    },
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#141416',
                    boxShadow: '0px 40px 32px -24px #B9B9B91F',
                    borderRadius: '24px',
                    border: '2px solid #353945'
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: '#777E90',
                    padding: '12px 16px',
                    '&:hover': {
                        color: '#F4F5F6',
                        backgroundColor: '#23262F'
                    }
                },
            },
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                ol: {
                    padding: `8px 16px`,
                    flexWrap: 'nowrap',
                    background: palette.primary.light,
                },
                li: {
                    fontFamily: FONT_FAMILY,
                    '&:last-child': {
                        overflow: 'hidden',
                        display: '-webkit-box',
                        wordWrap: 'break-word',
                        wordBreak: 'break-all',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                scrollPaper: {
                    '& .MuiPaper-root': {
                        margin: 0,
                    },
                },
                paperFullWidth: {
                    width: '100%',
                },
                paper: {
                    overflowY: 'unset',
                    maxWidth: '800px',
                    backgroundColor: 'transparent'
                },
                container: {
                    overflowY: 'auto'
                }
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    fontFamily: FONT_FAMILY,
                    padding: 16,
                    '&.without-bottom-padding': {
                        paddingBottom: 0,
                    },
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: { padding: 16 },
            },
        },
        MuiImageListItem: {
            styleOverrides: {
                root: {
                    fontFamily: FONT_FAMILY,
                    '&.table-view': {
                        flexDirection: 'row',
                        alignItems: 'center',
                        minHeight: '72px',
                        '&.padding': {
                            paddingLeft: '16px',
                            paddingRight: '16px',
                        },
                    },
                    '&.file-info': {
                        flexDirection: 'row',
                        alignItems: 'center',
                        minHeight: '56px',
                    },
                    '&.dialog-view': {
                        flexDirection: 'row',
                        alignItems: 'center',
                        minHeight: '56px',
                        '&.padding': {
                            paddingLeft: '16px',
                            paddingRight: '16px',
                        },
                    },
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    fontFamily: FONT_FAMILY,
                    '&.without-padding': {
                        paddingLeft: 0,
                        paddingRight: 0,
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    borderRadius: '12px',
                    border: '2px solid #353945',
                    fontFamily: FONT_FAMILY,
                    color: '#777E90',
                    fontSize: '14px',
                    lineHeight: '24px',
                    fontWeight: 500
                },
                icon: {
                    width: '36px',
                    height: '32px',
                    right: '6px',
                    top: '10px'
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    backgroundColor: '#141416',
                    color: '#FCFCFD'
                }
            }
        }
    },
});
