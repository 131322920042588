import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '~/routes/routes';
import { PersonalInfo } from '~/views/organisms/personal.info';
import { AccountPayments } from '~/views/organisms/account.payments';
import { BackBtn } from '~/views/atoms/back.btn';
import { AccountMenu } from '~/views/molecules/account.menu';
import { AccountMenuItem } from '~/views/enums';
export const PageAccount = observer(() => {
    const location = useLocation();
    const isPersonalInfoPage = useMemo(() => location.pathname === ROUTES.PERSONAL_INFO.path, [location.pathname]);
    return (_jsxs("div", { className: "container", children: [_jsx(BackBtn, { path: ROUTES.MAIN.path }), _jsxs("div", { className: "account-wrap", children: [_jsx(AccountMenu, { activeItem: isPersonalInfoPage ? AccountMenuItem.personalInfo : AccountMenuItem.payments }), isPersonalInfoPage ? _jsx(PersonalInfo, {}) : _jsx(AccountPayments, {})] })] }));
});
