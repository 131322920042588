var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from 'mobx';
import { Injectable } from './service.base';
import Cookies from 'js-cookie';
export let SignInDialogService = class SignInDialogService {
    open = false;
    showStep2 = false;
    showStep1 = true;
    showStep3 = false;
    btnDisabled = true;
    fullNumber;
    phoneNumber;
    codeLeftSeconds = 0;
    codeResendForbidden = false;
    resendCodeInvalidTimes = 0;
    phoneAsPassword = false;
    constructor() {
        makeObservable(this);
    }
    handleOpen = (e) => {
        e.preventDefault();
        this.open = true;
    };
    handleClose = () => {
        this.open = false;
        this.showStep1 = true;
        this.showStep2 = false;
        this.showStep3 = false;
    };
    handleOpenStep2 = () => {
        this.showStep1 = false;
        this.showStep2 = true;
    };
    handleOpenStep1 = (e) => {
        e.preventDefault();
        this.showStep1 = true;
        this.showStep2 = false;
    };
    handleOpenStep3 = () => {
        this.showStep3 = true;
        this.showStep2 = false;
        this.sendVerificationCode();
    };
    handlePhoneNumberChange = (isValid, fullNumber) => {
        this.btnDisabled = !isValid;
        this.fullNumber = fullNumber;
    };
    sendVerificationCode = () => {
        const delayResendCookie = Cookies.get('delayResendCookie');
        if (!delayResendCookie) {
            this.resendTimer(6);
            this.resendCodeInvalidTimes++;
        }
        else {
            this.setCodeResendForbidden(true);
        }
    };
    resendTimer = (seconds) => {
        this.codeLeftSeconds = seconds;
        const interval = setInterval(() => {
            if (this.codeLeftSeconds > 0) {
                this.decrementCodeLeftSeconds();
            }
            else {
                clearInterval(interval);
                if (this.resendCodeInvalidTimes > 4) {
                    const expMinutes = 10;
                    const date = new Date();
                    date.setTime(date.getTime() + (expMinutes * 60 * 1000));
                    Cookies.set('delayResendCookie', 'Yes', { path: '/', expires: date });
                    this.setCodeResendForbidden(true);
                }
            }
        }, 1000);
    };
    handleResendClick = (e) => {
        e.preventDefault();
        this.sendVerificationCode();
    };
    decrementCodeLeftSeconds = () => {
        this.codeLeftSeconds--;
    };
    setCodeResendForbidden = (value) => {
        this.codeResendForbidden = value;
    };
    handlePhoneEyeClick = () => {
        this.phoneAsPassword = !this.phoneAsPassword;
        const phoneInputType = this.phoneAsPassword ? 'password' : 'tel';
        const phoneInput = document.getElementById('sign-in-phone');
        // @ts-ignore
        phoneInput.setAttribute('type', phoneInputType);
    };
};
__decorate([
    observable,
    __metadata("design:type", Boolean)
], SignInDialogService.prototype, "open", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], SignInDialogService.prototype, "showStep2", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], SignInDialogService.prototype, "showStep1", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], SignInDialogService.prototype, "showStep3", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], SignInDialogService.prototype, "btnDisabled", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], SignInDialogService.prototype, "fullNumber", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], SignInDialogService.prototype, "phoneNumber", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], SignInDialogService.prototype, "codeLeftSeconds", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], SignInDialogService.prototype, "codeResendForbidden", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], SignInDialogService.prototype, "resendCodeInvalidTimes", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], SignInDialogService.prototype, "phoneAsPassword", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "handleOpen", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "handleClose", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "handleOpenStep2", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "handleOpenStep1", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "handleOpenStep3", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "handlePhoneNumberChange", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "sendVerificationCode", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "resendTimer", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "handleResendClick", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "decrementCodeLeftSeconds", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "setCodeResendForbidden", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "handlePhoneEyeClick", void 0);
SignInDialogService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [])
], SignInDialogService);
