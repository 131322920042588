var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { BaseSingletonService, Singleton } from './service.base';
import { SignInDialogService } from './service.signin.dialog';
import { LinkAccountDialogService } from './service.link.account.dialog';
import { RedeemDialogService } from './service.redeem.dialog';
export let DialogService = class DialogService extends BaseSingletonService {
    signInDialogSrv;
    linkAccountDialogSrv;
    redeemDialogSrv;
    constructor(signInDialogSrv, linkAccountDialogSrv, redeemDialogSrv) {
        super();
        this.signInDialogSrv = signInDialogSrv;
        this.linkAccountDialogSrv = linkAccountDialogSrv;
        this.redeemDialogSrv = redeemDialogSrv;
    }
};
DialogService = __decorate([
    Singleton(),
    __metadata("design:paramtypes", [typeof (_a = typeof SignInDialogService !== "undefined" && SignInDialogService) === "function" ? _a : Object, typeof (_b = typeof LinkAccountDialogService !== "undefined" && LinkAccountDialogService) === "function" ? _b : Object, typeof (_c = typeof RedeemDialogService !== "undefined" && RedeemDialogService) === "function" ? _c : Object])
], DialogService);
