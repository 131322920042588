var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { BaseSingletonService, Singleton } from './service.base';
import { PaymentService } from '~/services/service.payment';
import { AccountService } from '~/services/service.account';
import { AddCreditCardService } from '~/services/service.add.credit.card';
export let WrapperService = class WrapperService extends BaseSingletonService {
    paymentService;
    accountService;
    addCreditCardService;
    constructor(paymentService, accountService, addCreditCardService) {
        super();
        this.paymentService = paymentService;
        this.accountService = accountService;
        this.addCreditCardService = addCreditCardService;
    }
};
WrapperService = __decorate([
    Singleton(),
    __metadata("design:paramtypes", [typeof (_a = typeof PaymentService !== "undefined" && PaymentService) === "function" ? _a : Object, typeof (_b = typeof AccountService !== "undefined" && AccountService) === "function" ? _b : Object, typeof (_c = typeof AddCreditCardService !== "undefined" && AddCreditCardService) === "function" ? _c : Object])
], WrapperService);
