var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from 'mobx';
import { BaseService, Injectable } from './service.base';
export let UIOpenService = class UIOpenService extends BaseService {
    open = false;
    openedItem;
    constructor() {
        super();
        makeObservable(this);
    }
    handleOpen = () => {
        this.self.open = true;
        console.log('test');
    };
    handleClose = () => (this.self.open = false);
    handleToggle = () => (this.self.open = !this.open);
    setOpenedItem = (openedItem) => (this.self.openedItem = openedItem);
};
__decorate([
    observable,
    __metadata("design:type", Object)
], UIOpenService.prototype, "open", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], UIOpenService.prototype, "openedItem", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], UIOpenService.prototype, "handleOpen", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], UIOpenService.prototype, "handleClose", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], UIOpenService.prototype, "handleToggle", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], UIOpenService.prototype, "setOpenedItem", void 0);
UIOpenService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [])
], UIOpenService);
