var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { action, makeObservable, observable } from 'mobx';
import { BaseSingletonService, Singleton } from './service.base';
import { UIOpenService } from './service.ui';
import { api } from '~/tools/api/api';
import { ServiceConfig } from '~/services/service.config';
import { EApiUrl } from '~/tools/api/types';
export let AuthService = class AuthService extends BaseSingletonService {
    openPassSvc;
    openDialogSvc;
    serviceConfig;
    isLogged = false;
    constructor(openPassSvc, openDialogSvc, serviceConfig) {
        super();
        this.openPassSvc = openPassSvc;
        this.openDialogSvc = openDialogSvc;
        this.serviceConfig = serviceConfig;
        makeObservable(this);
    }
    login = async (data) => {
        try {
            const response = await api.post(EApiUrl.auth, data);
        }
        catch (error) {
        }
    };
    logout = () => {
    };
};
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AuthService.prototype, "isLogged", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AuthService.prototype, "login", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AuthService.prototype, "logout", void 0);
AuthService = __decorate([
    Singleton(),
    __metadata("design:paramtypes", [typeof (_a = typeof UIOpenService !== "undefined" && UIOpenService) === "function" ? _a : Object, typeof (_b = typeof UIOpenService !== "undefined" && UIOpenService) === "function" ? _b : Object, typeof (_c = typeof ServiceConfig !== "undefined" && ServiceConfig) === "function" ? _c : Object])
], AuthService);
