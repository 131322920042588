import axios from 'axios';
export const getBaseUrl = () => '';
export const api = {
    get: (url, params) => axios.get(url, {
        baseURL: getBaseUrl(),
        ...params,
        withCredentials: true,
    }),
    post: (url, data) => axios.post(url, data, {
        baseURL: getBaseUrl(),
    }),
    patch: (url, data) => axios.patch(url, data, {
        baseURL: getBaseUrl(),
    }),
    delete: (url) => axios.delete(url, {
        baseURL: getBaseUrl(),
    }),
};
