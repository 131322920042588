import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { ROUTES } from '~/routes/routes';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Select, MenuItem } from '@mui/material';
import { DropdownArrow } from '~/views/atoms/svg-icons/dropdown-arrow';
import { AccountMenuItem } from '~/views/enums';
export const AccountMenu = observer((props) => {
    let selectedPage = props.activeItem;
    const navigate = useNavigate();
    const onAccountPageChange = useCallback(() => {
        const path = selectedPage === AccountMenuItem.personalInfo ? ROUTES.ACCOUNT_PAYMENTS.path : ROUTES.PERSONAL_INFO.path;
        navigate(path);
    }, [navigate]);
    return (_jsxs("div", { className: "account-menu", children: [_jsxs("div", { className: "account-menu-inner", children: [_jsxs("div", { className: "account-menu-item", children: [_jsxs("figure", { children: [props.activeItem === AccountMenuItem.personalInfo && (_jsx("img", { src: "/assets/img/personal-info-active.svg" })), props.activeItem !== AccountMenuItem.personalInfo && (_jsx("img", { src: "/assets/img/personal-info.svg" }))] }), _jsx(Link, { className: props.activeItem === AccountMenuItem.personalInfo ? "account-active" : "", to: ROUTES.PERSONAL_INFO.path, children: "Personal info" })] }), _jsxs("div", { className: "account-menu-item", children: [_jsxs("figure", { children: [props.activeItem === AccountMenuItem.payments && (_jsx("img", { src: "/assets/img/account-payments-active.svg" })), props.activeItem !== AccountMenuItem.payments && (_jsx("img", { src: "/assets/img/account-payments.svg" }))] }), _jsx(Link, { className: props.activeItem === AccountMenuItem.payments ? "account-active" : "", to: ROUTES.ACCOUNT_PAYMENTS.path, children: "Payments" })] })] }), _jsx("div", { className: "account-menu-select", children: _jsxs(Select, { className: "hr-select", IconComponent: DropdownArrow, value: selectedPage, onChange: onAccountPageChange, children: [_jsx(MenuItem, { value: AccountMenuItem.personalInfo, children: "Personal info" }), _jsx(MenuItem, { value: AccountMenuItem.payments, children: "Payment methods" })] }) })] }));
});
