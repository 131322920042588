import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '~/routes/routes';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import { MenuItem } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { usePopupState, bindHover, bindMenu } from 'material-ui-popup-state/hooks';
export const UserTopMenu = observer((props) => {
    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'userMenu',
    });
    const navigate = useNavigate();
    const goToAccount = () => {
        popupState.close();
        navigate(ROUTES.PERSONAL_INFO.path);
    };
    return (_jsxs("div", { className: "top-menu-wrap", children: [_jsx("div", { className: "username-wrap", children: "Alex" }), _jsx("div", { className: "user-avatar-wrap dropdown-hover", ...bindHover(popupState), children: _jsx("figure", { children: _jsx("img", { src: "/assets/img/test-avatar.svg" }) }) }), _jsxs(HoverMenu, { className: "dropdown-menu", ...bindMenu(popupState), anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, transformOrigin: { vertical: 'top', horizontal: 'center' }, children: [_jsx(MenuItem, { children: _jsxs("div", { className: "dropdown-item", children: [_jsx("div", { className: "user-menu-icon", children: _jsx("figure", { children: _jsx("img", { src: "/assets/img/letter-w-user-menu.svg" }) }) }), _jsx("div", { className: "user-menu-text", children: "W Dollars $55" })] }) }), _jsx(MenuItem, { children: _jsxs("div", { className: "dropdown-item", children: [_jsx("div", { className: "user-menu-icon", children: _jsx("figure", { children: _jsx("img", { src: "/assets/img/free-play-user-menu.svg" }) }) }), _jsx("div", { className: "user-menu-text", children: "Freepaly $55" })] }) }), _jsx(MenuItem, { children: _jsxs("div", { className: "dropdown-item", children: [_jsx("div", { className: "user-menu-icon", children: _jsx("figure", { children: _jsx("img", { src: "/assets/img/unity-user-menu.svg" }) }) }), _jsx("div", { className: "user-menu-text", children: "Unity Points 550" })] }) }), _jsx(MenuItem, { children: _jsxs("div", { className: "user-menu-actions", children: [_jsx("button", { type: "button", className: "btn hr-blue-btn", onClick: goToAccount, children: "Account" }), _jsx("button", { type: "button", className: "btn hr-black-btn", onClick: popupState.close, children: "Log out" })] }) })] })] }));
});
