import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Fragment, useCallback } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AuthService } from '~/services/service.auth';
import { useService } from '~/services/service.base';
import { PageHeader } from '~/views/organisms/page.header';
import { PageFooter } from '~/views/organisms/page.footer';
const Main = styled('main')({});
export const AppWrapper = observer(() => {
    const auth = useService(AuthService);
    const nav = useNavigate();
    const { pathname } = useLocation();
    const handleLogoClick = useCallback(() => {
        location.href = auth.serviceConfig.mainPageRedirectUrl;
    }, [auth.serviceConfig.mainPageRedirectUrl]);
    const handleAccountClick = useCallback(() => {
    }, [nav]);
    return (_jsxs(Fragment, { children: [_jsx(PageHeader, {}), _jsx(Main, { children: _jsx(Outlet, {}) }), _jsx(PageFooter, {})] }));
});
